import React, { useState, useRef } from 'react';
import FeaturesForInvestors_1 from '../assets/FeaturesForInvestors-1.png';
import FeaturesForInvestors_2 from '../assets/FeaturesForInvestors-2.png';
import FeaturesForInvestors_3 from '../assets/FeaturesForInvestors-3.png';
import FeaturesForInvestors_4 from '../assets/FeaturesForInvestors-4.png';
import FeaturesForInvestors_5 from '../assets/FeaturesForInvestors-5.png';
import FeaturesForInvestors_6 from '../assets/FeaturesForInvestors-6.png';
import FeaturesForInvestors_7 from '../assets/FeaturesForInvestors-7.png';

const About = () => {
  <div>
    
  </div>
};

export default About;
